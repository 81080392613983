
import { Vue, Component, Prop } from 'vue-property-decorator';
import BaseButton from '@/components/baseComponents/BaseButton.vue';
import { IAgreementLinks } from '../../models';

@Component({
  components: { BaseButton },
})
export default class BasePopupForPages extends Vue {
  @Prop({ default: false }) isShowPrivacyPolicy!: boolean;
  @Prop({ default: false }) isShowTermsOfUse!: boolean;
  @Prop({ default: null }) actualLinks!: IAgreementLinks;

  goToLink(link: string): void {
    window.open(link, '_blank');
  }
  closePopup(): void {
    this.$emit('closePopup');
  }
}
